<template>
	<div>
		<div>{{id || '-'}}</div>
		<el-popover v-if="dailyInfo" placement="right" width="600" trigger="hover" :disabled="disabled" @after-enter="afterEnter" @after-leave="afterLevel">
			<div class="charts-box border">
				<div :id="chartId" class="charts"></div>
				<div :id="chartDataView" class="data-view-for-charts" style="display:none;"></div>
			</div>
			<div slot="reference" class="text-right">
				<small>{{dailyInfo}}</small>
			</div>
		</el-popover>
	</div>
</template>

<script>
	import * as echarts from 'echarts/core'
	import {
		TooltipComponent,
		GridComponent,
		ToolboxComponent,
		LegendComponent
	} from 'echarts/components'
	import {
		LineChart
	} from 'echarts/charts'
	import {
		UniversalTransition
	} from 'echarts/features'
	import {
		CanvasRenderer
	} from 'echarts/renderers'
	echarts.use([
		TooltipComponent,
		GridComponent,
		LineChart,
		CanvasRenderer,
		UniversalTransition,
		ToolboxComponent,
		LegendComponent
	]);
	import {
		uuid
	} from 'vue-uuid'
	import {
		fetchJSONByGet
	} from '@/apis/index.js'
	export default {
		props: {
			id: {
				type: [String, null],
				default: null
			},
			idType: {
				type: String,
				default: 'CAMPAIGN'
			}
		},
		data() {
			return {
				chartData: null,
				hasInit: false,
				chartKey: uuid.v4(),
				dailyInfo: null
			}
		},
		computed: {
			chartId() {
				return 'chart-' + this.chartKey
			},
			chartDataView() {
				return 'dataview-'+ this.chartKey
			},
			disabled() {
				return this.id === null
			}
		},
		mounted() {
			this.__init()
		},
		methods: {
			exportCsv(el) {
				const val = el.target.getAttribute('data-value')
				const {series, xAxis} = JSON.parse(val)
				const dates = xAxis[0].data
				const rows = dates.map((date, index) => {
					return {
						Date: date,
						...series.reduce((lhs, rhs) => {
							const {name, data} = rhs
							lhs[name] = Number(data[index])
							return lhs
						}, {})
					}
				})
				const title = `Charts Data(${xAxis[0].data[0]}~${xAxis[0].data[xAxis[0].data.length -1]})`
				const payload = {
					status: 'FINISH',
					title,
					content: [{rows, title: this.title}],
					showBadge: true
				}
				this.$store.commit('download-manager/addTask', payload)
			},
			afterEnter() {
				if (!this.hasInit) {
					this.hasInit = true
					echarts.init(document.getElementById(this.chartId))
				}
				if (this.chartData) {
					this.drawChart()
				}
			},
			afterLevel() {
				try {
					const dataViewId = '#dataview-' + this.chartKey
					document.querySelector(dataViewId).style.display='none';
				} catch (e) {}
			},
			drawChart() {
				this.buildOptions().then(option => {
					const myChart = echarts.getInstanceByDom(document.getElementById(this.chartId))
					if (myChart) {
						myChart.setOption(option)
					}
				})
			},
			ceilIntervalNumber(number, min = 100) {
				var bite = 0;
				if (number < min) {
					return min;
				}
				if (number < 1 && min < 1) {
					number = Math.ceil(number * 1000);
					return ceilIntervalNumber(number) / 1000;
				}
				while (number >= 10) {
					number /= 10;
					bite += 1;
				}
				return Math.ceil(number) * Math.pow(10, bite);
			},
			calcRoi(costs, revs, days) {
				var len = costs.length;
				var all_cost = costs.slice(Math.max(0, len - days), ).reduce((l, r) => l + r, 0);
				var all_rev = revs.slice(Math.max(0, len - days), ).reduce((l, r) => l + r, 0);
				var v = all_cost / all_rev;
				if (isNaN(v) || v === Infinity) {
					return 0;
				}
				return Number(v.toFixed(2));
			},
			__init() {
				if (!this.id || this.chartData) return
				fetchJSONByGet('/adms_api_v0/chart/inject/today', {
						id: this.id,
						idType: this.idType
					})
					.then(res => {
						this.chartData = res || []
						this.buildOptions()
					})
					.catch(err => {
						setTimeout(() => {this.__init()}, 4000)
					})
			},
			async buildOptions() {
				return new Promise((resolve, reject) => {
					const data = this.chartData
					const costs = []
					const revs = []
					const orders = []
					const times = Array(24).fill('').map((v, i) => `${i}`)
					var gmv = 0
					var orderNumbers = 0
					data.forEach(v => {
						costs.push(v.cost)
						revs.push(v.revenue)
						orders.push(Number(v.orderCounts))
						orderNumbers += Number(v.orderCounts)
						gmv += v.revenue
					});
					gmv = gmv.toFixed(2)
					const roi = this.calcRoi(costs, revs, 24)
					const maxLeft = Math.max(...costs, ...revs)
					const maxRight = Math.max(...orders)
					const splitNumber = 6;
					const leftInterval = this.ceilIntervalNumber(maxLeft / splitNumber, maxLeft / splitNumber < 1 ?
						0.1 : 1)
					const rightInterval = this.ceilIntervalNumber(maxRight / splitNumber, 1)
					const that = this
					that.dailyInfo = `${roi}/${orderNumbers}/${gmv}`
					const option = {
						color: ["#00ff00", "#ffff00", "#000000"],
						toolbox: {
							show: true,
							showTitle: false,
							feature: {
								saveAsImage: {
									 pixelRatio: 2
								},
								myMore: {
									show: true,
									icon: `path://M176 1024h672c26.464 0 48-21.536 48-48v-672c0-0.544-0.256-0.992-0.288-1.504-0.064-0.736-0.256-1.376-0.416-2.08a15.616 15.616 0 0 0-2.88-6.112c-0.224-0.288-0.192-0.64-0.416-0.928l-256-288c-0.096-0.096-0.224-0.096-0.32-0.192a15.808 15.808 0 0 0-7.136-4.288c-0.608-0.192-1.152-0.256-1.792-0.352C625.824 0.384 624.96 0 624 0h-448C149.536 0 128 21.536 128 48v928c0 26.464 21.536 48 48 48zM640 58.08L844.384 288H656c-7.04 0-16-13.44-16-24V58.08zM160 48a16 16 0 0 1 16-16H608v232c0 25.888 20.96 56 48 56H864v656a16 16 0 0 1-16 16h-672c-8.96 0-16-7.04-16-16v-928z;path://M304 448h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 576h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 320h192a16 16 0 0 0 0-32h-192a16 16 0 0 0 0 32zM304 704h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32zM304 832h416a16 16 0 0 0 0-32h-416a16 16 0 0 0 0 32z`,
									title: '数据',
									onclick({option}) {
										const {xAxis, series} = option
										const axisData = xAxis[0].data
										const dateCol = '<td><table border cellspacing="0"><tr><th>HOUR</th></tr>' + axisData.map(s => `<tr><td>${s}</td></tr>`).join('') + '</table></td>'
										var otherCols = series.map(s => {
											const prefillData = Array(axisData.length).fill('-').map((v, i) => {
												if (i < s.data.length) {
													return s.data[i]
												}
												return v
											})
											return '<td><table border cellspacing="0">'+ `<tr><th>${s.name.toUpperCase()}</th></tr>` + prefillData.map(d => `<tr><td>${d}</td></tr>`).join('') + '</table></td>'
										})
										const exportCsvId = '#export-csv-' + that.chartKey
										const dataViewId = '#dataview-' + that.chartKey
										const el = document.querySelector(dataViewId)
										const val = JSON.stringify({xAxis, series})
										const closeBtn = `<div class="mb-2 text-right"><button id="export-csv-data-${that.chartKey}" class="el-button el-button--text el-button--mini text-success" data-value='${val}'>导出</button><button class="el-button el-button--text el-button--mini" onclick="document.querySelector('${dataViewId}').style.display='none';">关闭</button></div>`
										el.innerHTML = closeBtn + '<div class="chart-data-table-container"><div><table class="chart-data-table" cellspacing="0"><tr>' + dateCol + otherCols.join('') + '</tr></table></div></div>'
										el.style.display = 'flex'
										document.querySelector(`#export-csv-data-${that.chartKey}`).addEventListener('click', that.exportCsv)
									}
								}
							}
						},
						legend: {
							bottom: 0
						},
						xAxis: [{
							type: "category",
							axisLine: {
								onZero: false
							},
							data: times,
							axisTick: {
								show: false
							}
						}],
						yAxis: [{
								type: "value",
								axisLine: {
									show: false
								},
								splitNumber,
								interval: leftInterval,
								max: splitNumber * leftInterval,
								axisLabel: {
									formatter: (value) => {
										return `$${value.toFixed(2)}`;
									}
								}
							},
							{
								type: "value",
								axisLine: {
									show: false
								},
								splitNumber,
								interval: rightInterval,
								max: splitNumber * rightInterval,
							}
						],
						series: [{
								name: "cost",
								type: "line",
								data: costs,
								showSymbol: false
							},
							{
								name: "rev",
								type: "line",
								data: revs,
								showSymbol: false
							},
							{
								name: "orders",
								type: "line",
								data: orders,
								showSymbol: false,
								yAxisIndex: 1
							}
						],
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: 'cross',
								animation: false,
								label: {
									backgroundColor: '#505765'
								}
							}
						}
					};
					resolve(option)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.charts-box {
		width: max-content;
		height: max-content;
		position: relative;

		.charts {
			min-width: 570px;
			min-height: 400px;
			width: 100%;
			height: 100%;
		}

		.el-button {
			position: absolute;
			right: 10px;
			top: 0;
			z-index: 500;
		}
	}

	:deep(.data-view-for-charts) {
		flex-direction: column;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		padding: 10px;
		overflow: hidden;

		table {
			font-size: 12px;
			text-align: center;
			min-width: max-content;
			width: 100%;
			box-sizing: content-box;
		}

		.chart-data-table-container {
			overflow: auto;
			flex: 1;
			height: 100%;

			.chart-data-table>tbody>tr>td {
				padding: 0;
			}
		}
	}
</style>
