<template>
	<div class="budget-plan-logs">
		<table>
			<tr>
				<td width="100px"><small>调整级别:</small></td>
				<td><small>{{item.level}}</small></td>
			</tr>
			<tr>
				<td><small>ID:</small></td>
				<td><small>{{item.adSetId || item.campaignId}}</small></td>
			</tr>
			<tr>
				<td><small>Name:</small></td>
				<td><small>{{item.adSetName || item.campaignName}}</small></td>
			</tr>
		</table>
		<div v-for="(log, index) in logs" class="budget-plan-log">
			<div>{{log | message}}</div>
		</div>
		<div class="h-100" :class="{'d-none': !loading}" v-loading="loading" element-loading-text="加载中..."></div>
	</div>
</template>

<script>
	import Moment from 'moment'
	export default {
		props: {
			item: {
				type: Object,
				default: () => {}
			},
			namespace: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				loading: false,
				logs: []
			}
		},
		filters: {
			message(log) {
				var text = ''
				try {
					text += `${log.createdBy.name} ` || '系统 '
				} catch(e) {
					text += '系统 '
				}
				try {
					const body = JSON.parse(log.body)
					text += body.description
				} catch (e) {}
				return Moment(Number(log.createdAt)).format('YYYY-MM-DD HH:mm') + ' ' + text
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				this.loading = true
				const { id } = this.item
				this.$store.dispatch(`${this.namespace}/getLogs`, id)
					.then((content) => {
						this.logs = content
						this.loading = false
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.loading = false
					})
			}
		}
	}
</script>

<style lang="scss">
	.budget-plan-logs {
		padding: 10px;
		overflow-y: auto;
		height: 100%;

		.budget-plan-log {
			margin-bottom: 20px;
			padding: 15px 10px;
			font-size: 14px;
			border: 1px solid #ddd;
			border-radius: 3px;
		}
	}
</style>
