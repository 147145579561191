<template>
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website" :clearable="false" @update="resetAccount"></form-item-sites>
					<form-item label="渠道:">
						<el-select v-model="queryForm.channel" size="mini" @change="resetAccount">
							<el-option v-for="item in channels" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="clickUpload">上传计划</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="账户:" style="width:350px">
						<el-select v-model="queryForm.accountId" clearable size="mini" @focus="loadAccounts" :loading="loading.account" remote class="w-100">
							<el-option v-for="(account,index) in accounts" :label="account.name" :value="account.id" :key="account.id">
								<span style="float: left">{{ account.name }}</span>
								<span style="float: right; color: #8492a6; font-size: 13px">{{ account.id }}</span>
							</el-option>
						</el-select>
					</form-item>
					<form-item label="计划来源:">
						<el-select v-model="queryForm.source" clearable size="mini">
							<el-option v-for="item in sources" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="调整结果:">
						<el-select v-model="queryForm.direction" clearable size="mini">
							<el-option v-for="item in directions" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="出价方式:">
						<el-select v-model="queryForm.bidType" clearable size="mini">
							<el-option v-for="item in bidTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="选择日期:" style="width:400px;">
						<el-date-picker v-model="queryForm.adjustDate" type="date" value-format="yyyy-MM-dd" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<div v-if="tableData.length > 0" class="budget-plan-container">
				<div class="d-flex text-center flex-row align-items-center justify-content-center">
					<el-button type="primary"  size="mini" @click="confirm('ALL')">确认全部计划</el-button>
					<el-button type="primary"  size="mini" @click="confirm('INCREASE')">确认上涨计划</el-button>
					<el-button type="primary"  size="mini" @click="confirm('REDUCE')">确认下降计划</el-button>
				</div>
				<div v-for="(item, index) in tableData" :key="index" class="my-3">
					<div class="d-flex flex-row align-content-center justify-content-between">
						<div>{{item.channel}} {{item.accountId}}</div>
						<div>
							<el-button type="text" size="mini" :disabled="disabledConfirmButton(item, 'ALL')" @click="confirm('ALL', item.data)">确认全部计划</el-button>
							<el-button type="text" size="mini" :disabled="disabledConfirmButton(item, 'INCREASE')" @click="confirm('INCREASE', item.data)">确认上涨计划</el-button>
							<el-button type="text" size="mini" :disabled="disabledConfirmButton(item, 'REDUCE')" @click="confirm('REDUCE', item.data)">确认下降计划</el-button>
						</div>
					</div>
					<el-table :data="item.data" size="mini" :row-class-name="tableRowClassName" header-row-class-name="table-header">
					    <el-table-column
							align="center"
							label="来源"
							min-width="100">
							<template slot-scope="scope">
								{{scope.row.source|getLabel(sources)}}
							</template>
					    </el-table-column>
						<el-table-column
							align="center"
							prop="campaignName"
							label="Campaign"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="campaignId"
							label="Campaign ID"
							min-width="150">
							<template slot-scope="scope">
								<chart :id="scope.row.campaignId" idType="CAMPAIGN"></chart>
							</template>
						</el-table-column>
						<el-table-column
							align="center"
							prop="level"
							label="预算级别"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="adSetName"
							label="AdSet"
							min-width="150"
							:formatter="formatterWithDefault">
						</el-table-column>
						<el-table-column
							align="center"
							prop="adSetId"
							label="Adset ID"
							min-width="150">
							<template slot-scope="scope">
								<chart :id="scope.row.adSetId" idType="AD_SET"></chart>
							</template>
						</el-table-column>
						<el-table-column
							align="center"
							prop="data1"
							label="1d_data"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="data3"
							label="3d_data"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="data7"
							label="7d_data"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="currentBudget"
							label="now_budget"
							min-width="100">
						</el-table-column>
						<el-table-column
							align="center"
							prop="targetBudget"
							label="target budget"
							min-width="150">
							<template slot-scope="scope">
								<div v-if="scope.row.sync_status">{{scope.row.targetBudget}}</div>
								<div v-else>
									<el-input-number v-model="scope.row.value" :placeholder="`${scope.row.targetBudget}`" size="mini" :controls="false" :min="10" @blur="updateTargetBudget(scope.row)"></el-input-number>
								</div>
							</template>
						</el-table-column>
						<el-table-column
							align="center"
							label="调整规则"
							min-width="180">
							<template slot-scope="scope">
								<el-popover
								  width="400"
								  :disabled="scope.row.sourceRuleBody.length === 0"
								  trigger="hover">
								  <div v-for="(strategy,index) in scope.row.sourceRuleBody" :key="index" :class="{'border-bottom': index !== scope.row.sourceRuleBody.length -1}" class="text-left">
								  	<div>Expressions</div>
								  	<div>{{strategy|expressions}}</div>
								  	<div>Strategy</div>
								  	<div>{{strategy|strategy}}</div>
								  </div>
								  <div slot="reference" class="text-truncate">{{scope.row.sourceRuleBody|getSummary}}</div>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column
							align="center"
							label="操作结果"
							prop="syncNote"
							min-width="180">
						</el-table-column>
						<el-table-column
							align="center"
							label="操作"
							min-width="180">
							<template slot-scope="scope">
								<el-button v-if="!scope.row.sync_status" type="text" size="mini" @click="confirm('ALL', scope.row)">确认</el-button>
								<el-button type="text" size="mini" @click="viewHistory(scope.row)">历史记录</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-drawer
			  title="调整历史"
			  id="budget-plan-logs"
			  :visible.sync="visible.log"
			  direction="rtl"
			  :size="400"
			  :before-close="handleClose">
			  <logs :key="logsKey" :item="selectedItem" :namespace="namespace"></logs>
			</el-drawer>
			<el-dialog title="导入Budget调整计划" :visible.sync="visible.upload" width="600px" :before-close="handleClose">
				<el-radio-group v-model="form.channel">
				  <el-radio v-for="item in channels" :label="item.label" :key="item.value">{{item.value}}</el-radio>
				</el-radio-group>
				<div class="d-flex flex-row align-items-center">
					<div>下载</div>
					<el-button type="text" class="text-success ml-2" @click="exportCsv">调整原始数据&模板</el-button>
					<el-button type="text" class="mr-2" @click="refreshRawData">刷新原数据</el-button>
					<div>{{lastSyncTime()}}</div>
				</div>
				<el-upload
					class="text-center mt-2"
					:key="form.uploadKey"
					drag
					action="/"
					accept=".csv"
					:auto-upload="false"
					:file-list="fileList"
					:before-remove="beforeRemove"
					:on-change="beforeUpload">
					<i class="el-icon-upload text-primary"></i>
					<div class="el-upload__text">
						<div>点击或将文件拖拽道这里上传</div>
						<div style="color:#8492a6;">支持扩展名: .csv</div>
					</div>
				</el-upload>
				<div slot="footer" class="dialog-footer">
					<el-button size="mini" @click="visible.upload=false" >取 消</el-button>
					<el-button type="primary" size="mini" @click="clickImport">上传并导入</el-button>
				</div>
			</el-dialog>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Common from '@/common/mixins/common.js'
	import {uuid} from 'vue-uuid'
	import Logs from '@/components/budget/logs.vue'
	import Chart from '@/components/budget/chart.vue'
	import {mapGetters} from 'vuex'
	import {fetchJSONByPost} from '@/apis/index.js'
	import Config from '../mixins/index.js'
	export default {
		mixins: [Common, Config],
		inject: ['adminLayout'],
		components: {
			Logs,
			Chart
		},
		data() {
			return {
				queryForm: {
					website: '',
					bidType: '',
					channel: '',
					accountId: '',
					source: '',
					direction: '',
					adjustDate: null
				},
				q: {},
				tableData: [],
				namespace: 'budget-plan',
				trigger: false,
				visible: {
					log: false,
					upload: false
				},
				logsKey: uuid.v4(),
				selectedItem: {},
				accounts: [],
				loading: {
					account: false
				},
				cachedAccounts: {},
				form: {
					channel: '',
					file: null,
					uploadKey: uuid.v4(),
					syncTime: null
				},
				fileList: []
			}
		},
		created() {
			this.queryForm.adjustDate = Moment().format('YYYY-MM-DD')
			this.queryForm.channel = this.channels[0].value
		},
		computed: {
			...mapGetters({
				channels: 'budget-plan/channels',
				sources: 'budget-plan/sources',
				directions: 'budget-plan/directions',
				bidTypes: 'budget-plan/bidTypes',
				syncStatusList: 'budget-plan/syncStatusList'
			})
		},
		filters: {
			getLabel(key, data) {
				try {
					return data.find(v => v.value === key).label
				} catch(e) {}
			},
			getSummary(sourceRuleBody) {
				try {
					return sourceRuleBody[0].condition.expressions.map((v, index) => {
						var text = ''
						if (index > 0) {
							text += v.operator
						}
						if (v.valueLeft && v.symbolLeft) {
							text += ` ${v.valueLeft} ${v.symbolLeft}`
						}
						text += ` ${v.variables} `
						if (v.valueRight && v.symbolRight) {
							text += ` ${v.symbolRight} ${v.valueRight}`
						}
						return text
					}).join(' ')
				} catch(e) {
					console.log(e, sourceRuleBody)
					return ''
				}
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.q
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			handleList(content) {
				this.tableData = Object.keys(content).map(key => {
					const values = key.split('/')
					const channel = values[1]
					const accountId = values[0]
					const data = content[key]
					return {
						channel,
						accountId,
						data: data.map(k => {
							var sourceRuleBody = []
							try {sourceRuleBody = JSON.parse(k.sourceRuleBody) || []} catch(e) {}
							return {
								...k,
								value: k.targetBudget,
								sync_status: this.syncStatusList[k.syncStatus] || false,
								sourceRuleBody
							}
						})
					}
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				this.getList()
			},
			getList() {
				this.adminLayout.showLoading()
				this.getListUrl()
				.then(data => {
					this.adminLayout.hideLoading()
					if (data) {
						this.handleList(data)
					} else {
						this.tableData = []
					}
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
				this.trigger = true
			},
			disabledConfirmButton(item, key) {
				switch (key) {
					case 'ALL':
						return item.data.find(v => v.sync_status === false) === undefined
					case 'INCREASE':
						return item.data.find(v => v.sync_status === false && v.targetBudget > v.currentBudget) === undefined
					case 'REDUCE':
						return item.data.find(v => v.sync_status === false && v.targetBudget < v.currentBudget) === undefined
				}
				return false
			},
			formatterWithDefault(row, column, cellValue, index) {
				if (!!cellValue) {
					return cellValue
				}
				return '-'
			},
			tableRowClassName({row, rowIndex}) {
				if (row.currentBudget < row.targetBudget) return 'up-row'
				if (row.currentBudget > row.targetBudget) return 'down-row'
				return ''
			},
			viewHistory(item) {
				this.selectedItem = item
				this.logsKey = uuid.v4()
				this.visible.log = true
			},
			loadAccounts() {
				const payload = {website:this.queryForm.website, channel: this.queryForm.channel}
				const _accounts = this.cachedAccounts[Object.keys(payload)]
				if (_accounts) {
					this.accounts = _accounts
					return
				}
				this.loading.account = true
				this.$store.dispatch(`${this.namespace}/getAccounts`, payload)
				.then(accounts => {
					const _accounts = Object.keys(accounts).map(id => {
						return {
							id,
							name: accounts[id]
						}
					})
					this.accounts = _accounts
					this.loading.account = false
					if (_accounts.length > 0) {
						this.cachedAccounts[Object.keys(payload)] = _accounts
					}
				})
				.catch(err => {
					this.loading.account = false
				})
			},
			resetAccount() {
				this.accounts = []
				this.queryForm.accountId = ''
			},
			clickUpload() {
				this.form.channel = 'FACEBOOK'
				this.fileList = []
				this.form.uploadKey = uuid.v4()
				this.form.file = null
				this.form.syncTime = {}
				this.visible.upload = true
				this.$store.dispatch(`${this.namespace}/getSyncTime`, this.queryForm.website)
				.then(res => {
					try {
						const syncTime = {}
						res.forEach(v => {
							syncTime[v.channel] = v.lastSyncTime
						})
						this.form.syncTime = syncTime
					} catch(e) {}
					console.log(res)
				})
			},
			beforeRemove(file, fileList) {
				this.form.file = null
				this.fileList = []
				return true
			},
			beforeUpload(file, fileList) {
				this.form.file = file
				this.fileList = fileList.length > 0 ? [fileList[fileList.length - 1]] : []
			},
			clickImport() {
				if (!this.q.website || !this.form.file) return
				this.adminLayout.showLoading(true)
				var body = new FormData()
				body.append('file', this.form.file.raw)
				const headers = {
					"Content-Type": "multipart/form-data"
				}
				fetchJSONByPost(`/adms_api_v0/facebook/adjust/plan/import/${this.queryForm.website}/${this.form.channel}`, body, {
					headers
				})
				.then(res => {
					this.adminLayout.hideLoading()
					this.$showSuccess('计划上传成功')
				})
				.catch(err => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(err)
				})
			},
			lastSyncTime() {
				if (!this.form.syncTime) return
				const _lastSyncTime = this.form.syncTime[this.form.channel]
				if (_lastSyncTime) {
					return '上次更新时间' + Moment(Number(_lastSyncTime)).format('YYYY-MM-DD HH:mm:ss')
				}
				return
			},
			updateTargetBudget(row) {
				this.$store.dispatch(`${this.namespace}/updateBudget`, [{id: row.id, targetBudget: row.value}])
				.then(res => {
					row.targetBudget = res[0].targetBudget
					
				})
			},
			confirm(action, data = null) {
				var ids = []
				if (Array.isArray(data)) {
					switch(action) {
						case 'ALL':
							ids = data.filter(v => !v.sync_status).map(v => v.id)
							break
						case 'INCREASE':
							ids = data.filter(v => !v.sync_status && v.targetBudget > v.currentBudget).map(v => v.id)
							break
						case 'REDUCE':
							ids = data.filter(v => !v.sync_status && v.targetBudget < v.currentBudget).map(v => v.id)
							break
					}
				} else if (data && data !== null) {
					ids = [data.id]
				} else {
					const allData = this.tableData.map(v => v.data).reduce((lhs, rhs) => lhs.concat(rhs), [])
					return this.confirm(action, allData)
				}
				if (ids.length === 0) return
				this.$confirm('您已点击了`确认`按钮, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/confirmBudgetPlans`, ids)
					.then(res => {
						this.adminLayout.hideLoading()
						this.getList()
					})
					.catch(err => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(err)
					})
				})
			},
			refreshRawData() {
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/refreshRawData`, {websiteCode: this.queryForm.website, channel: this.form.channel})
				.then(res => {
					this.adminLayout.hideLoading()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			},
			exportCsv() {
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/exportRawData`, {website: this.queryForm.website, channel: this.form.channel})
				.then(res => {
					const { data, status, headers } = res
					const content_disposition = headers['content-disposition']
					const ext = content_disposition.match(/(\.[\w]+)/g) || ['.zip']
					if (status === 200 && typeof data === 'object') {
						this.exportFile(data, `budget${ext[0]}`)
					}
					this.adminLayout.hideLoading()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			}
		}
	}
</script>
<style lang="scss">
	.budget-plan-container {
		flex:1;
		overflow-y:auto;
		.el-table .table-header th {
			background: #f3f3f3;
		}
		.el-table .down-row {
		    background: #FDEAEC;
		}
		
		.el-table .up-row {
		    background: #f0f9eb;
		}
	}
</style>

